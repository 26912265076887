import SmsIcon from "@mui/icons-material/Sms";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EmailIcon from "@mui/icons-material/AlternateEmail";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Typography,
  Link as MUILink,
  useTheme,
  Container,
  ThemeProvider,
} from "@mui/material";
import Button from "components/Button/Button";
import { ExpandMore } from "@mui/icons-material";
import Link from "next/link";
import WebsiteV2 from "layouts/WebsiteV2";
import analytics from "config/analytics";
import { useRouter } from "next/router";
import MarvyPricingTable from "components/MarvyPricingTable";
import plans from "commons/plans";
import humanizeDuration from "humanize-duration";
import { MT } from "components/MarvyTypography";
import React from "react";
import Column from "components/atoms/Column";
import Row from "components/atoms/Row";
import { useAppState } from "lib/appState";
import MarvyPricingMay23 from "components/MarvyPricingMay23";
import { darkTheme, lightTheme } from "lib/theme";
import WebhooksIcon from "components/icons/WebhooksIcon";
import SlackIcon from "components/icons/SlackIcon";
import MarvyButtonCard from "components/Cards/MarvyButtonCard";
import { emitGTMEvent } from "lib/events";

export default function LPMarvy1({ strings }) {
  const theme = useTheme();
  const [appState, setAppState] = useAppState();
  const router = useRouter();
  const forceTrial = false;
  const trialOnClick = forceTrial
    ? analytics.linkWithEvent(
        analytics.beginCheckoutFreeTrialWithCardEvent,
        process.env.NEXT_PUBLIC_START_TRIAL_LINK || "/auth/register",
        router
      )
    : undefined;

  const dotMatrix = React.useMemo(() => {
    return new Array(60).fill(0).map((_, i) =>
      new Array(20).fill(0).map((_, j) =>
        j * j * 0.15 > i ? (
          <circle
            cx={10 + j * 15}
            cy={10 + i * 15}
            r="2.5"
            // key={i + "" + j}
            style={{
              fill: Math.random() < 0.02 ? "#FF6666" : "#139A43",
            }}
          ></circle>
        ) : null
      )
    );
  }, []);

  return (
    <>
      <WebsiteV2>
        <Box
          component="svg"
          width="305px"
          height="850px"
          sx={{
            position: "absolute",
            right: "0px",
            top: { xs: "60px", lg: "110px" },
            zIndex: -1,
            opacity: 0.5,
          }}
        >
          {dotMatrix}
        </Box>
        <Column
          sx={{
            alignSelf: "stretch",
            minHeight: "calc(100vh - 110px - 50px)",
            justifyContent: "space-evenly",
          }}
        >
          <MT
            component="span"
            variant="h1"
            sx={{
              fontWeight: "800",
              color: "black",
              fontSize: {
                xs: "2rem",
                md: "4.5rem",
              },
              fontFamily: "'Open Sans', sans-serif",
              display: "inline",
              backgroundColor: "#ffffffbb",
              textAlign: "start",
              alignSelf: "stretch",
            }}
          >
            We <MT sx={{ color: "secondary.main" }}>alert</MT> you <br />
            when your <MT sx={{ color: "secondary.main" }}>sites</MT> go{" "}
            <MT sx={{ color: "secondary.main" }}>down</MT>.
          </MT>
          <Box sx={{ mb: 12 }}></Box>
          <Row
            sx={{
              gap: 3,
              justifyContent: "flex-start",
              alignSelf: "stretch",
              flexDirection: {
                xs: "column",
                sm: "row",
              },
            }}
          >
            {appState.user ? (
              <>
                <Link href="/dashboard" passHref>
                  <Button gradient>Dashboard</Button>
                </Link>
              </>
            ) : (
              <>
                <Link href="/auth/register" passHref>
                  <Button gradient>Register for free</Button>
                </Link>
              </>
            )}
            <Link href="#pricing" passHref>
              <Button>Features and Pricing</Button>
            </Link>
          </Row>
        </Column>
        <ThemeProvider theme={lightTheme}>
          <Row
            sx={{
              alignSelf: "center",
              width: "100vw",
              py: 8,
              mb: 18,
              mt: 12,
            }}
          >
            <Container sx={{}}>
              <Row
                sx={{
                  flexDirection: {
                    xs: "column",
                    sm: "row-reverse",
                  },
                }}
              >
                <Column sx={{ flex: 1, justifyContent: "center", pb: 3 }}>
                  <MT
                    variant="h2"
                    sx={{
                      color: "black",
                      fontWeight: "700",
                      fontSize: {
                        xs: "1.8rem",
                        md: "3rem",
                      },
                      fontFamily: "'Open Sans', sans-serif",
                      display: "inline",
                      mb: 2,
                      textAlign: {
                        xs: "center",
                        sm: "start",
                      },
                    }}
                  >
                    Get notified.
                  </MT>
                  <MT sx={{ textAlign: "justify" }}>
                    Catch and fix downtime before it impacts your customers. Get
                    the peace of mind of knowing that if it went down you'd
                    know.
                  </MT>
                </Column>
                <Row
                  sx={{
                    flex: 1,
                    justifyContent: "center",
                    paddingRight: { sm: 2 },
                  }}
                >
                  <Row
                    sx={{
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                      gap: 3,
                      maxWidth: "274px",
                    }}
                  >
                    {[
                      [
                        "Email",
                        (props) => (
                          <EmailIcon sx={{ color: "#000" }} {...props} />
                        ),
                      ],
                      [
                        "SMS",
                        (props) => (
                          <SmsIcon sx={{ color: "#000" }} {...props} />
                        ),
                      ],
                      ["Slack", SlackIcon],
                      ["Webhooks", WebhooksIcon],
                    ].map(([name, Icon]) => {
                      return (
                        <Column sx={{ alignItems: "center" }}>
                          <MarvyButtonCard
                            border
                            hover={false}
                            sx={{
                              mb: 0,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              minWidth: "125px",
                              py: 3,
                            }}
                            light
                            pointer={false}
                          >
                            <MT sx={{ color: "#000" }}>{name}</MT>
                            <Icon fontSize="large" />
                          </MarvyButtonCard>
                        </Column>
                      );
                    })}
                    <MarvyButtonCard
                      border
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        // flex: 2,
                        width: "298px",
                        py: 3,
                      }}
                      light
                      onClick={async () => {
                        router.push("/pricing/features");
                        await emitGTMEvent({
                          event: "track",
                          eventName: `click_view_all_notification_channels`,
                        });
                      }}
                    >
                      <MT sx={{ color: "#000" }}>View All</MT>
                      <MoreHorizIcon fontSize="large" />
                    </MarvyButtonCard>
                    <Box sx={{ width: "125px" }}></Box>
                  </Row>
                </Row>
              </Row>
            </Container>
          </Row>
        </ThemeProvider>
        <Column
          sx={{
            mt: "14px",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            height: {
              xs: null,
              md: "40vw",
            },
            alignItems: "center",
            marginRight: {
              xs: null,
              md: "50vw",
              lg: "600px",
            },
          }}
        >
          <Column
            sx={{
              justifyContent: "flex-start",
            }}
          >
            <MT
              variant="h2"
              sx={{
                fontWeight: "700",
                color: "black",
                fontSize: {
                  xs: "1.8rem",
                  md: "3rem",
                },
                fontFamily: "'Open Sans', sans-serif",
                display: "inline",
                mb: 4,
              }}
              align="start"
            >
              Public Status Pages
            </MT>
            <MT
              sx={{
                textAlign: "start",
                mt: 3,
                display: {
                  xs: "none",
                  md: "initial",
                },
              }}
            >
              Share the status of your services with your customers and
              stakeholders in real time.
            </MT>
          </Column>
          <Box
            component="img"
            sx={{
              marginLeft: "-16px",
              marginRight: "-16px",
              maxWidth: "100vw",
              width: {
                md: "50vw",
              },
              position: {
                xs: null,
                md: "absolute",
              },
              right: {
                xs: null,
                md: "0px",
              },
              zIndex: -1,
            }}
            alt="google status page on uptimeline"
            src="/img/status-page-preview.jpg"
          />
          <MT
            sx={{
              textAlign: "justify",
              mt: 3,
              display: {
                xs: null,
                md: "none",
              },
            }}
          >
            Share the status of your services with your customers and
            stakeholders in real time.
          </MT>
        </Column>
        <Box sx={{ mt: 20 }} />
        {
          // eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/anchor-has-content
          <a style={{ position: "relative", top: "-100px" }} name="pricing"></a>
        }
        <MT
          variant="h2"
          sx={{
            fontWeight: "700",
            color: "black",
            fontSize: {
              xs: "1.8rem",
              md: "3rem",
            },
            fontFamily: "'Open Sans', sans-serif",
            display: "inline",
            mb: 4,
            mt: 4,
          }}
          align="start"
        >
          Features and Pricing
        </MT>
        <MarvyPricingMay23 />
        <Box
          sx={{
            marginBottom: 16,
          }}
        ></Box>
        <MT
          variant="h2"
          sx={{
            fontWeight: "700",
            color: "black",
            fontSize: {
              xs: "1.8rem",
              md: "3rem",
            },
            fontFamily: "'Open Sans', sans-serif",
            display: "inline",
            mb: 4,
          }}
          align="center"
        >
          Set it up in under a minute
        </MT>
        <Paper
          elevation={4}
          sx={{
            borderRadius: 2,
            overflow: "hidden",
            marginBottom: 16,
          }}
        >
          <Box
            component="iframe"
            width="1120"
            height="630"
            src="https://www.youtube.com/embed/exr6OjSo3OI?controls=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            sx={{
              maxWidth: "100%",
              width: {
                xs: "300px",
                sm: "500px",
                md: "700px",
                lg: "900px",
              },
              height: {
                xs: "170px",
                sm: "282px",
                md: "396px",
                lg: "510px",
              },
            }}
          ></Box>
        </Paper>
        <Column sx={{ px: 3, alignItems: "stretch" }}>
          <MT
            variant="h2"
            sx={{
              fontWeight: "700",
              color: "black",
              fontSize: {
                xs: "1.8rem",
                md: "3rem",
              },
              fontFamily: "'Open Sans', sans-serif",
              display: "inline",
              mb: 4,
            }}
            align="center"
          >
            Who we are
          </MT>
          <MT sx={{ textAlign: "justify" }}>
            Uptimeline is a tiny independent company. We’re just getting
            started, we're on the hunt for our first clients and currently have
            the grand total of two paying customers, and about a thousand free
            users. Yes, you read that right, TWO. We're so proud of our
            hard-earned progress that we update the counter by hand (we keep the
            fancy algorithms for other things!). So join us on this wild ride
            and let's make it a party of three! 😎
            <br />
            <br />
            Our product doesn’t compete yet with bigger names like{" "}
            <MUILink sx={{}} href="https://uptimerobot.com">
              UptimeRobot
            </MUILink>
            , if you want the largest collection of features you should probably
            head out to their site instead.
            <br />
            But this is not all bad. Having few clients also means that:
            <ul style={{ listStyleType: "circle" }}>
              <li>
                I'll personally be making sure that you're happy with our
                service
              </li>
              <li>
                You can request new features and might actually see them
                implemented in days!
              </li>
              <li>
                We can make very custom configurations of our product for you
              </li>
            </ul>
          </MT>
        </Column>
        <Box sx={{ mb: 4 }}></Box>
        <MT
          variant="h2"
          sx={{
            fontWeight: "700",
            color: "black",
            fontSize: {
              xs: "1.8rem",
              md: "3rem",
            },
            fontFamily: "'Open Sans', sans-serif",
            display: "inline",
            mb: 4,
          }}
          align="center"
        >
          Our Values
        </MT>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignSelf: "stretch",
            flexWrap: "wrap",
          }}
        >
          {[
            {
              title: "Transparency",
              body: `We believe in being ourselves. We can’t be good at everything,
              we’d rather you know what we’re not good at and chose to stick
              around for what we are good at.`,
            },
            {
              title: "Excellence",
              body: `It can only be achieved through consistency and continuous improvement. We want to leave a mark in how the internet is monitored.`,
            },
            {
              title: "Happiness",
              body: `Happy customers keep paying the bills. We wanna make people happy. Should we disappear from the face of Earth, we want to be missed.`,
            },
          ].map(({ title, body }) => {
            return (
              <Box
                sx={{
                  flex: 1,
                  px: 3,
                  minWidth: "200px",
                  mb: 4,
                }}
              >
                <MT
                  sx={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    textAlign: "center",
                    mb: 2,
                  }}
                >
                  {title}
                </MT>
                <MT sx={{ textAlign: "justify", textJustify: "inter-word" }}>
                  {body}
                </MT>
              </Box>
            );
          })}
        </Box>

        <MT
          variant="h2"
          sx={{
            fontWeight: "700",
            color: "black",
            fontSize: {
              xs: "1.8rem",
              md: "3rem",
            },
            fontFamily: "'Open Sans', sans-serif",
            display: "inline",
            mb: 4,
          }}
          align="center"
        >
          Who I am
        </MT>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            gap: 4,
            px: 3,
          }}
        >
          <img
            alt="Cris Pintea"
            style={{ borderRadius: "50%", width: "200px", height: "200px" }}
            src="/img/cris.jpeg"
          />
          <MT sx={{ textAlign: "justify" }}>
            I'm Cris Pintea, a 23-year-old software developer hailing from
            Turin, Italy. My passion for software development started at a young
            age, and I began earning income through coding when I was just 15.
            During my journey, I had the opportunity to raise 300k€ of venture
            capital for a startup, although it unfortunately did not succeed.
            <br />
            Through this experience, I gained valuable insights and developed a
            fresh perspective on the type of entrepreneur I aspire to be. I
            discovered that what truly excites me is the process of learning and
            continuously enhancing a product.
          </MT>
        </Box>
        <Column sx={{ px: 3 }}>
          <MT
            variant="h2"
            sx={{
              fontWeight: "700",
              color: "black",
              fontSize: {
                xs: "1.8rem",
                md: "3rem",
              },
              fontFamily: "'Open Sans', sans-serif",
              display: "inline",
              mb: 2,
              mt: 8,
            }}
            align="center"
          >
            Our Mission
          </MT>
          <MT sx={{ textAlign: "justify" }}>
            Make monitoring websites magical. That feeling you get when you’re
            using a product that ingeniously engineered, it seems like the
            product is using itself. We want setting up website monitoring to
            feel like a magical experience. We’d like one day to make people
            suddenly excited when they have to add a new website to their
            monitoring service.
          </MT>
        </Column>
        <Typography
          variant="h1"
          sx={{
            fontWeight: "800",
            color: "black",
            fontSize: {
              xs: "2rem",
              md: "4.5rem",
            },
            fontFamily: "'Open Sans', sans-serif",
            marginTop: 16,
            marginBottom: 8,
          }}
          align="center"
        >
          Questions
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ fontWeight: 600 }}>
                I need to set up many monitors, can you help?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                You can send us a spreadsheet with the monitors you'd like to
                set up, and we will create them for you!
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ fontWeight: 600 }}>
                Can the monitors make POST requests?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Yes, you can select the HTTP method you want the monitor to use.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ fontWeight: 600 }}>
                Can I monitor authenticated APIs?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Yes, you can set arbitrary HTTP headers to be sent with the
                request, so you can for example use the Authorization header.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ fontWeight: 600 }}>
                I need help, how do I get in touch?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                You can use the chat in the bottom right corner of our website
                to chat with our customer service.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Typography
          align="center"
          sx={{
            fontWeight: "800",
            color: "black",
            fontSize: "1.3rem",
            fontFamily: "'Open Sans', sans-serif",
            marginTop: 4,
          }}
        >
          We're online.{" "}
          <button
            style={{ textDecoration: "underline" }}
            onClick={(e) => {
              e.preventDefault();
              window.$crisp.push(["do", "chat:open"]);
            }}
          >
            Chat with us!
          </button>
        </Typography>
        <Typography
          align="center"
          sx={{
            fontWeight: "800",
            color: "black",
            fontSize: "2rem",
            fontFamily: "'Open Sans', sans-serif",
            marginTop: 16,
            marginBottom: 4,
          }}
        >
          Ready to go?{" "}
        </Typography>
        <Link
          href={
            forceTrial
              ? process.env.NEXT_PUBLIC_START_TRIAL_LINK || "/auth/register"
              : "/auth/register"
          }
        >
          <Button onClick={trialOnClick} gradient>
            {forceTrial ? "start free trial" : "sign up"}
          </Button>
        </Link>
        <Box sx={{ height: 300 }}></Box>
      </WebsiteV2>
    </>
  );
}
