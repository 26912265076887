import Column from "components/atoms/Column";

export default function MarvyButtonCard({
  pointer = true,
  selected = false,
  hover = true,
  border = false,
  light = false,
  ...props
}) {
  const borderColor = light ? "#0003" : "#fff3";
  return (
    <Column
      {...props}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        p: "10px",
        cursor: pointer ? "pointer" : "default",
        borderRadius: "4px",
        border: `1px solid ${border ? borderColor : "transparent"}`,

        ...(hover
          ? {
              "&:hover": {
                backgroundColor: light
                  ? "rgba(0, 0, 0, 0.1)"
                  : "rgba(255, 255, 255, 0.1)",
                border: light ? "1px solid #000000" : "1px solid #ffffff",
              },
            }
          : {}),
        userSelect: "none",
        WebkitUserSelect: "none",
        ...(selected
          ? {
              borderColor: "primary.main",

              ...(hover
                ? {
                    "&:hover": {
                      backgroundColor: "rgba(19, 154, 67, 0.1)",
                    },
                  }
                : {}),
            }
          : {}),
        ...(props.sx || {}),
      }}
    />
  );
}
