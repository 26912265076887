import { Box } from "@mui/material";
import { forwardRef } from "react";

function Row({ sx, ...props }, ref) {
  return (
    <Box
      ref={ref}
      sx={{ display: "flex", flexDirection: "row", ...sx }}
      {...props}
    />
  );
}

export default forwardRef(Row);
