import LPMarvy1 from "components/lps/Marvy1";

export default function S1() {
  return (
    <LPMarvy1
      strings={{
        pricing: "Prezzi",
        blog: "Blog",
      }}
    />
  );
}
